import {useCallback} from 'react';
import {useAtomValue} from 'jotai';

import {UserToken, CommonLoginResponse} from '@santa-web/gen/services';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {SnsSignupAndSigninInfo} from '@app/features/auth/types';

const useSnsLogin = (): ((request: SnsSignupAndSigninInfo | null | undefined) => Promise<UserToken>) => {
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);

  return useCallback(
    async request => {
      if (request == null) throw new Error('request cannot be null in login');

      let response: CommonLoginResponse;
      switch (request.socialSignupVariant) {
        case 'APPLE':
          response = await AuthService.loginWithApple({
            loginWithAppleRequest: {appleRegistrationInfo: request.registrationInfo},
          });
          break;
        case 'GOOGLE':
          response = await AuthService.loginWithGoogle({
            loginWithGoogleRequest: {googleRegistrationInfo: request.registrationInfo},
          });
          break;
        case 'KAKAO':
          response = await AuthService.loginWithKakao({
            loginWithKakaoRequest: {kakaoRegistrationInfo: request.registrationInfo},
          });
          break;
        case 'LINE':
          response = await AuthService.loginWithLine({
            loginWithLineRequest: {lineRegistrationInfo: request.registrationInfo},
          });
      }
      return response.userToken;
    },
    [AuthService]
  );
};

export default useSnsLogin;
